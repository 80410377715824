<template>
  <AtlasDealPromotionCard
    :type="type"
    :headline="headline"
    :url="url"
    :call-to-action="callToAction"
  >
    <template v-if="description" slot="description">
      {{ description }}
    </template>
    <template v-if="termsAndConditions" slot="termsAndConditions">
      <JsonRichText v-bind="termsAndConditions" />
    </template>
  </AtlasDealPromotionCard>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import AtlasDealPromotionCard from "atlas/src/components/DealPromotionCard/DealPromotionCard.vue";
import { DealPromotionCardProps } from "./Props";
import JsonRichText from "~/components/JsonRichText/JsonRichText.vue";

export default Vue.extend({
  name: "DealPromotionCard",
  components: { JsonRichText, AtlasDealPromotionCard },
  props: {
    type: {
      type: String as PropType<DealPromotionCardProps["type"]>,
      required: true,
    },
    headline: {
      type: String as PropType<DealPromotionCardProps["headline"]>,
      required: true,
    },
    description: {
      type: String as PropType<DealPromotionCardProps["description"]>,
      default: undefined,
    },
    termsAndConditions: {
      type: Object as PropType<DealPromotionCardProps["termsAndConditions"]>,
      default: undefined,
    },
    url: {
      type: String as PropType<DealPromotionCardProps["url"]>,
      default: undefined,
    },
    callToAction: {
      type: String as PropType<DealPromotionCardProps["callToAction"]>,
      default: undefined,
    },
  },
});
</script>
