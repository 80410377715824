<template>
  <div data-cy="departures-room-messaging__multi-share">
    <p>Please note, room options differ for each trip.</p>

    <p>
      <span class="u-font-weight--bold u-display--block">Share a room</span>
      Depending on the trip, your accomodation may be a twin-share room or tent,
      or an all-gender room with multiple beds.
    </p>

    <p>
      Find all the room details for your trip in the Essential Trip Information
      or
      <Link href="/contact-us" target="_blank">contact us</Link>.
    </p>

    <p>
      <span class="u-font-weight--bold u-display--block"
        >Want your own room?</span
      >
      You can
      <Link href="/contact-us" target="_blank"
        >contact us to request your own room</Link
      >
      on most trips for an additional cost. Please note, there's limited
      availability.
    </p>
  </div>
</template>

<script lang="ts">
import Link from "../../Link/Link.vue";

export default {
  name: "DeparturesRoomMessagingMultishare",
  components: {
    Link,
  },
};
</script>
