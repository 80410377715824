<template>
  <p
    class="
      departures-single-supplement-messaging
      u-margin-top--1
      u-margin-bottom--0
    "
    data-cy="departures-single-supplement-messaging"
  >
    <template
      v-if="
        singleSupplementPrice &&
        singleSupplementPrice.price &&
        hasValidSingleSupplement
      "
      >Request your own room from
      <Price
        :value="singleSupplementPrice.price"
        :currencyCode="singleSupplementPrice.currencyCode.toLocaleUpperCase()"
        :currency-locale="getLocaleByCode($i18n.locale).currencyLocale"
        :showDecimalPlaces="false"
      />
    </template>
    <template v-else>Own room option is unavailable</template>
  </p>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Price from "atlas/src/components/Price/Price.vue";
import { DeparturesDayRoom } from "../Props";
import { isPriceValid } from "../utils/isPriceValid";
import { getLocaleByCode } from "~/lib/utils/locale/getLocaleByCode";

export default Vue.extend({
  name: "DeparturesSingleSupplementMessaging",
  components: {
    Price,
  },
  props: {
    singleSupplementPrice: {
      type: Object as PropType<DeparturesDayRoom["singleSupplementPrice"]>,
      required: false,
      default: undefined,
    },
  },
  computed: {
    hasValidSingleSupplement(): boolean {
      return isPriceValid(this.singleSupplementPrice);
    },
  },
  methods: { getLocaleByCode },
});
</script>

<style lang="scss">
@import "./departures-single-supplement-messaging";
</style>
