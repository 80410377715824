var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.hasValidRoomType && _vm.hasValidPrice)?_c('div',{staticClass:"departures-room-option-price__price-details",attrs:{"data-cy":"departures-room-option-price"}},[_c('div',{staticClass:"departures-room-option-price__price-wrapper"},[_c('Price',{class:[
        _vm.hasValidDiscountedPrice
          ? 'departures-room-option-price__price-when-has-discounted-price'
          : 'departures-room-option-price__price-when-has-not-discounted-price',
        'departures-room-option-price__price',
        'u-margin-right--0-5',
      ],attrs:{"value":_vm.asValue(_vm.price.price),"currencyCode":_vm.price.currencyCode.toLocaleUpperCase(),"currency-locale":_vm.getLocaleByCode(_vm.$i18n.locale).currencyLocale,"showCurrencyCode":true,"showDecimalPlaces":false}}),_vm._v(" "),(_vm.hasValidDiscountedPrice && _vm.price.discountedPrice)?_c('Price',{staticClass:"departures-room-option-price__discounted-price",attrs:{"value":_vm.price.discountedPrice,"currency-locale":_vm.getLocaleByCode(_vm.$i18n.locale).currencyLocale,"currencyCode":_vm.price.currencyCode.toLocaleUpperCase(),"showCurrencyCode":true,"showDecimalPlaces":false}}):_vm._e()],1),_vm._v(" "),_c('p',{staticClass:"departures-room-option-price__messaging u-margin-bottom--0"},[_vm._v("\n    per\n    "),_c('span',{staticClass:"u-font-weight--bold"},[_vm._v(_vm._s(_vm.price.ageType))]),_vm._v("\n    in a\n    "),_c('span',{staticClass:"u-font-weight--bold"},[_vm._v(_vm._s(_vm.getTranslatedRoomType(_vm.roomType)))]),_vm._v("\n    room\n  ")])]):_c('div',{staticClass:"departures-room-option-price__price-unavailability-details"},[_c('span',{staticClass:"departures-room-option-price__price-unavailable"},[_vm._v("\n    Price unavailable\n  ")]),_vm._v(" "),_c('p',{staticClass:"departures-room-option-price__contact-details u-margin-bottom--0"},[_c('Link',{attrs:{"href":"/contact-us"}},[_vm._v("Contact us")]),_vm._v(", prices are currently\n    unavailable\n  ")],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }