<template>
  <div
    v-if="vehicleName"
    class="departures-vehicle-details"
    data-cy="departures-vehicle-details"
  >
    <div class="departures-vehicle-details__icon">
      <Icon name="boat-outline" role="presentation" width="40px" />
    </div>
    <div>
      <span class="u-font-weight--bold sm:u-margin-right--0-5">
        Vessel: {{ vehicleName }}</span
      >
      <Link
        :href="$link.create(`/boats-ships`)"
        class="departures-vehicle-details__contact-details"
        >Learn more about our ships</Link
      >
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import Link from "../../Link/Link.vue";

export default Vue.extend({
  name: "DeparturesVehicleDetails",
  components: {
    Link,
    Icon,
  },
  props: {
    vehicleName: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss">
@import "./departures-vehicle-details";
</style>
