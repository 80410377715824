<template>
  <a
    class="departures-room-details-link__modal u-cursor--pointer"
    href="#"
    @click.stop.prevent="() => $emit('roomDetailsClicked', room)"
  >
    View room details and sharing options</a
  >
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { DeparturesDayRoom } from "../Props";

export default Vue.extend({
  name: "DeparturesRoomDetailsLink",
  props: {
    room: {
      type: Object as PropType<DeparturesDayRoom>,
      required: true,
    },
  },
});
</script>

<style lang="scss">
@import "./departures-room-details-link";
</style>
