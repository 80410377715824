<template>
  <div class="departures-room-option" data-cy="departures-room-option">
    <div class="departures-room-option__room-type-and-availability-details">
      <template v-if="roomType">
        <span
          class="headline--6 u-margin-top--0 u-margin-bottom--0"
          data-cy="departures-room-option-type"
        >
          {{ getTranslatedRoomType(roomType).toUpperCase() }}
        </span>
        <div
          class="departures-room-option__space-left-and-book-request-messaging"
          data-cy="departures-room-option-availability"
        >
          <a
            v-if="isOnRequest && isAvailable"
            data-cy="departures-room-option__modal-button"
            aria-label="View on request modal"
            href="#"
            @click.stop.prevent="openModal"
            >On request</a
          >
          <span
            v-if="
              getSpacesLeftMessage &&
              !isFamilyTripWithOneAvailability &&
              isOnRequest
            "
          >
            -
          </span>
          <span
            v-if="getSpacesLeftMessage && !isFamilyTripWithOneAvailability"
            >{{ getSpacesLeftMessage }}</span
          >
        </div>
      </template>
    </div>

    <div
      class="departures-room-option__pricing-details-and-messaging"
      data-cy="departures-room-option-pricing-messaging"
    >
      <div
        v-if="!isAvailable"
        class="departures-room-option__pricing-details-fully-booked"
      >
        <span class="headline--5 u-margin-top--0 u-margin-bottom--0">
          Fully booked
        </span>
        <p class="departures-room-option__contact-details u-margin-bottom--0">
          <Link href="/contact-us">Contact us</Link> to arrange an alternative
          departure
        </p>
      </div>

      <div
        v-else-if="isFamilyTripWithOneAvailability"
        class="
          departures-room-option__pricing-details-family-trip-with-one-availability
        "
      >
        <span class="headline--5 u-margin-top--0 u-margin-bottom--0">
          One space left
        </span>
        <p class="departures-room-option__contact-details u-margin-bottom--0">
          <Link href="/contact-us">Contact us</Link> to discuss this departure
        </p>
      </div>

      <div v-else>
        <DeparturesRoomOptionPrice
          v-for="(roomOptionPrice, index) in roomOptionPrices"
          :key="index"
          class="departures-room-option__room-option-price"
          :price="roomOptionPrice"
          :roomType="roomType"
        />
        <slot />
      </div>
    </div>
    <DeparturesRoomOptionModal :show-modal="showModal" @close="closeModal" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import DeparturesRoomOptionPrice from "../DeparturesRoomOptionPrice/DeparturesRoomOptionPrice.vue";
import Link from "../../Link/Link.vue";
import { isAvailable } from "../utils/isAvailable";
import { isFamilyTripWithOneAvailability } from "../utils/isFamilyTripWithOneAvailability";
import { isAvailabilityBetweenZeroAndFive } from "../utils/isAvailabilityBetweenZeroAndFive";
import { DeparturesDayRoomOptionPrice } from "../Props";
import { getTranslatedRoomType } from "../utils/getTranslatedRoomType";
import DeparturesRoomOptionModal from "./DeparturesRoomOptionModal.vue";

export default Vue.extend({
  name: "DeparturesRoomOption",
  components: {
    DeparturesRoomOptionPrice,
    Link,
    DeparturesRoomOptionModal,
  },
  props: {
    roomType: {
      type: String,
      required: true,
    },
    isOnRequest: {
      type: Boolean,
      required: true,
    },
    availability: {
      type: Number,
      required: true,
    },
    isFamilyTrip: {
      type: Boolean,
      required: true,
    },
    roomOptionPrices: {
      type: Array as PropType<DeparturesDayRoomOptionPrice[]>,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    getSpacesLeftMessage(): string {
      return isAvailabilityBetweenZeroAndFive(this.availability)
        ? `${this.$tc("space", this.availability, {
            n: this.availability,
          })} left`
        : ``;
    },
    isFamilyTripWithOneAvailability(): boolean {
      return isFamilyTripWithOneAvailability(
        this.isFamilyTrip,
        this.availability
      );
    },
    isAvailable(): boolean {
      return isAvailable(this.availability);
    },
  },

  methods: {
    getTranslatedRoomType(roomType: string) {
      return getTranslatedRoomType(roomType);
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
});
</script>

<style lang="scss">
@import "./departures-room-option";
</style>
