<template>
  <div data-cy="departures-room-messaging__vrtt-solo-travellers--no-share">
    <p>
      Please note, room options and availability differs for each trip as our
      ships are all different.
    </p>

    <p>
      <span class="u-font-weight--bold u-display--block"
        >Travelling with others?</span
      >
      You can share a room with people you’re travelling with or book a single
      occupancy room.
    </p>

    <p>
      <span class="u-font-weight--bold u-display--block">Travelling solo?</span>
      Please book a single occupancy room as this trip doesn’t allow you to
      share a room with other travellers.
    </p>

    <p>
      <span class="u-font-weight--bold u-display--block"
        >Want to know more?</span
      >
      <Link href="/boats-ships" target="_blank"
        >Explore the deck plan and features for your ship</Link
      >
      to see your room options in detail. You can also find room details for
      your trip in the Essential Trip Information, or
      <Link href="/contact-us" target="_blank">contact us</Link>.
    </p>
  </div>
</template>

<script lang="ts">
import Link from "../../Link/Link.vue";

export default {
  name: "DeparturesRoomMessagingVRTTSoloTravellersNoShare",
  components: {
    Link,
  },
};
</script>
