<template>
  <div data-cy="departures-room-messaging__vrtt-solo-travellers--share">
    <p>
      Please note, room options and availability differs for each trip as our
      ships are all different.
    </p>

    <p>
      <span class="u-font-weight--bold u-display--block">Share a room</span>
      You can share a twin or triple room with travellers of the same gender (as
      per the gender marker on their passports), or a twin or double room with
      someone you’re travelling with.
    </p>

    <p>
      <span class="u-font-weight--bold u-display--block"
        >Want your own room?</span
      >
      Choose a twin or double room and we’ll contact you to pay an additional
      cost to make it your own. Please note, there’s limited availability, so
      we’ll do our best to accommodate you.
    </p>

    <p>
      <span class="u-font-weight--bold u-display--block"
        >Want to know more?</span
      >
      <Link href="/boats-ships" target="_blank"
        >Explore the deck plan and features for your ship</Link
      >
      to see your room options in detail. You can also find all the room details
      for your trip in the Essential Trip Information, or
      <Link href="/contact-us" target="_blank">contact us</Link>.
    </p>
  </div>
</template>

<script lang="ts">
import Link from "../../Link/Link.vue";

export default {
  name: "DeparturesRoomMessagingVRTTSoloTravellersShare",
  components: {
    Link,
  },
};
</script>
