<template>
  <div data-cy="departures-room-messaging__family">
    <p>
      <span class="u-font-weight--bold u-display--block">Twin share</span>
      Share a room with 2 single beds with a traveller of the same gender (as
      per the gender marker on their passports) or someone you’re travelling
      with.
    </p>

    <p>
      If your gender identity differs from what is indicated on your passport,
      please <Link href="/contact-us" target="_blank">contact us</Link> to
      discuss rooming options.
    </p>

    <p>
      Travelling as a couple?
      <Link href="/contact-us" target="_blank"
        >Contact us to request a double bed.</Link
      >
    </p>

    <p>
      <span class="u-font-weight--bold u-display--block">Children</span>
      Depending on your family size we’ll do our best to fit you in a room that
      fits you all. If we can’t, we’ll make sure children are in a room with a
      parent. Please note, children must be under 18 at the time of departure.
    </p>

    <p>
      <span class="u-font-weight--bold u-display--block"
        >Want to know more?</span
      >
      Find all the room details in the Essential Trip Information for your trip
      or <Link href="/contact-us" target="_blank">contact us</Link>.
    </p>
  </div>
</template>

<script lang="ts">
import Link from "../../Link/Link.vue";

export default {
  name: "DeparturesRoomMessagingFamily",
  components: {
    Link,
  },
};
</script>
