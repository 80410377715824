<template>
  <Card
    :image="thumbnail"
    data-cy="deals-search-card"
    :href="$link.create(url)"
  >
    <template slot="heading">
      <strong
        class="u-display--block u-margin-bottom--1"
        data-cy="deals-search-card-title"
      >
        Trips on Sale
      </strong>
    </template>
    <template slot="content">
      <p data-cy="deals-search-card-description">
        See more of the world for less and save on your next adventure.
      </p>
    </template>
  </Card>
</template>

<script lang="ts">
import { PropType } from "vue";
import Card from "atlas/src/components/Card/Card.vue";
import { DealsSearchCardProps } from "./Props";

export default {
  name: "DealsSearchCard",
  components: { Card },
  props: {
    url: {
      type: String as PropType<DealsSearchCardProps["url"]>,
      required: false,
      default: undefined,
    },
    thumbnail: {
      type: Object as PropType<DealsSearchCardProps["thumbnail"]>,
      required: false,
      default: undefined,
    },
  },
};
</script>
