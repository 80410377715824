<template>
  <div
    class="
      departures-accommodation-details
      u-font-weight--bold
      u-margin-bottom--0-5
    "
  >
    <template v-if="isMultipleStyles || isOneStyleAndMultipleRooms">
      Select accommodation
    </template>
    <template v-else>Accommodation details</template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { DeparturesDay } from "../Props";

export default Vue.extend({
  name: "DeparturesAccommodationDetails",
  props: {
    styles: {
      type: Array as PropType<DeparturesDay["styles"]>,
      required: true,
    },
  },
  computed: {
    isMultipleStyles(): boolean {
      return this.styles.length > 1;
    },
    isOneStyleAndMultipleRooms(): boolean {
      return this.styles.length === 1 && this.styles[0].rooms.length > 1;
    },
  },
});
</script>
