<template>
  <Tile
    :title="title"
    :icon="`no-booking-dates`"
    data-cy="departures-no-results"
    class="tile--x-large"
  >
    <span v-if="pageType !== 'destinationMonth'">
      Keep the adventure alive
      <template
        v-if="mainCountry && mainCountry.displayName && mainCountry.url"
      >
        by
        <AppLink :to="$link.create(mainCountry.url)"
          >exploring similar trips in {{ mainCountry.displayName }}</AppLink
        >
        or
      </template>
      <template v-else>,</template>
      <AppLink :to="$link.create('/search')">search all trips</AppLink>.
    </span>
    <span v-if="pageType === 'destinationMonth'">
      <template
        v-if="mainCountry && mainCountry.displayName && mainCountry.url"
      >
        <AppLink :to="$link.create(mainCountry.url)">
          Explore all {{ mainCountry.displayName }} trips
        </AppLink>
        or check a different month.
      </template>
    </span>
  </Tile>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import AppLink from "atlas/src/components/AppLink/AppLink.vue";
import { DeparturesProps } from "../Props";

export default Vue.extend({
  name: "DeparturesNoResults",
  components: {
    Tile,
    AppLink,
  },
  props: {
    mainCountry: {
      type: Object as PropType<DeparturesProps["mainCountry"]>,
      required: true,
    },
    pageType: {
      type: String as PropType<DeparturesProps["pageType"]>,
      required: false,
      default: undefined,
    },
    title: {
      type: String as PropType<DeparturesProps["title"]>,
      required: false,
      default: "We have no departures scheduled for this trip",
    },
  },
});
</script>
