<template>
  <Button
    :on-click="() => $emit('pagination-clicked')"
    class="button--secondary button--block"
    :disabled="isLoading"
  >
    <template v-if="isLoading">
      <Spinner
        :show="true"
        aria-label="Loading more departures"
        class="u-margin-right--1 icon--size-2"
      />
      {{ fetchingMoreDeparturesText }}
    </template>
    <template v-else>
      {{ paginationButtonText }}
      <Icon name="chevron-down" class="u-icon--size-2" />
    </template>
  </Button>
</template>

<script lang="ts">
import Vue from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import Spinner from "atlas/src/components/Spinner/Spinner.vue";

export default Vue.extend({
  name: "DeparturesPaginationButton",
  components: {
    Button,
    Icon,
    Spinner,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      paginationButtonText: "View more dates",
      fetchingMoreDeparturesText: "Loading more dates...",
    };
  },
});
</script>
