<template>
  <Page v-bind="page">
    <ErrorBoundary
      v-if="introduction"
      class="l-container l-container--small u-margin-top--2 sm:u-margin-top--6"
    >
      <Introduction v-bind="introduction" />
    </ErrorBoundary>

    <ErrorBoundary
      v-if="dealsList?.deals && dealsList?.deals.length > 0"
      class="l-container"
    >
      <DealsList
        v-bind="dealsList"
        class="u-margin-top--4"
        data-cy="latest-deals-list"
      />
    </ErrorBoundary>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { DealsPageProps } from "./Props";
import Page from "~~/components/Page/Page.vue";
import Introduction from "~/components/Introduction/Introduction.vue";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import DealsList from "~/components/DealsList/DealsList.vue";

export default Vue.extend({
  name: "DealsPage",
  components: { Page, Introduction, ErrorBoundary, DealsList },
  props: {
    page: {
      type: Object as PropType<DealsPageProps["page"]>,
      required: true,
    },
    introduction: {
      type: Object as PropType<DealsPageProps["introduction"]>,
      required: false,
      default: undefined,
    },
    dealsList: {
      type: Object as PropType<DealsPageProps["dealsList"]>,
      required: false,
      default: null,
    },
  },
});
</script>
