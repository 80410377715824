<template>
  <div
    data-cy="departures-day-columns"
    class="departures-day-columns u-padding-bottom--0-5"
  >
    <div class="departures-day-columns__start-date u-font-weight--bold">
      Starting
    </div>
    <div class="departures-day-columns__end-date u-font-weight--bold">
      Ending
    </div>
    <div class="departures-day-columns__options"></div>
    <div class="departures-day-columns__on-sale"></div>
    <div
      class="
        departures-day-columns__prices
        u-text-align--right
        u-font-weight--bold
      "
    >
      Prices from
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "DeparturesDayColumns",
});
</script>

<style lang="scss">
@import "./departures-day-columns";
</style>
