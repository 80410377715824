import Vue from "vue";
import dayjs from "dayjs";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import "atlas/src/components/Icon/icons/arrow-right";
import "atlas/src/components/Icon/icons/expand";
import "atlas/src/components/Icon/icons/chevron-right";
import "atlas/src/components/Icon/icons/chevron-down";
import Button from "atlas/src/components/Button/Button.vue";
import Modal from "atlas/src/components/Modal/Modal.vue";
import Price from "atlas/src/components/Price/Price.vue";
import Link from "../Link/Link.vue";
import DeparturesNoResults from ".././Departures/DeparturesNoResults/DeparturesNoResults.vue";
import { contextualFetch } from "~~/lib/utils/contextualFetch";
import { getLocaleByCode } from "~/lib/utils/locale/getLocaleByCode";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
const logger = loggerFactory({
  tags: [logTags.Layer.Component]
});
const DEPARTURES_PER_BATCH = 6;
export default Vue.extend({
  name: "DepartureFeaturedList",
  components: {
    Imagery,
    Icon,
    Button,
    Modal,
    Price,
    Link,
    DeparturesNoResults
  },
  props: {
    destinationName: {
      type: String,
      required: true
    },
    destinationType: {
      type: String,
      required: true
    },
    destinationMonth: {
      type: String,
      required: false,
      default: undefined
    },
    destinationTitle: {
      type: String,
      required: false,
      default: undefined
    },
    destinationNoDeparturesTitle: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      maxInitialDepartures: 6,
      modalData: null,
      departures: [],
      departuresNoResultsData: {
        displayName: "",
        url: ""
      },
      getTopDealsProcessCompletedStatus: false,
      currentDisplayIndex: DEPARTURES_PER_BATCH
    };
  },
  computed: {
    filteredDepartures() {
      return this.departures.filter((_, index) => index < this.maxTotalDepartures);
    },
    currencyCode() {
      var _this$$route$params$c, _this$$route, _this$$route$params;
      return ((_this$$route$params$c = (_this$$route = this.$route) === null || _this$$route === void 0 ? void 0 : (_this$$route$params = _this$$route.params) === null || _this$$route$params === void 0 ? void 0 : _this$$route$params.currencyCode) !== null && _this$$route$params$c !== void 0 ? _this$$route$params$c : this.$i18n.localeProperties.currencyCode).toLocaleUpperCase();
    },
    currencyLocale() {
      return getLocaleByCode(this.$i18n.locale).currencyLocale;
    },
    destinationDisplayName() {
      return this.destinationTitle ? this.destinationTitle : `Top ${this.destinationName} travel deals`;
    },
    departureValidationProcessStatus() {
      return this.destinationMonth ? this.getTopDealsProcessCompletedStatus : this.filteredDepartures.length > 0 && this.getTopDealsProcessCompletedStatus;
    },
    maxTotalDepartures() {
      return this.destinationMonth ? 40 : 10;
    },
    displayShowMoreButton() {
      return this.currentDisplayIndex < this.filteredDepartures.length;
    }
  },
  async created() {
    if (process.client) {
      var _this$$i18n, _this$$i18n$localePro, _this$$i18n2, _this$$route2, _this$$route3, _this$$route3$params;
      const requestParams = {
        locale_iso: (_this$$i18n = this.$i18n) === null || _this$$i18n === void 0 ? void 0 : (_this$$i18n$localePro = _this$$i18n.localeProperties) === null || _this$$i18n$localePro === void 0 ? void 0 : _this$$i18n$localePro.iso,
        locale_code: (_this$$i18n2 = this.$i18n) === null || _this$$i18n2 === void 0 ? void 0 : _this$$i18n2.locale,
        locale_domain: useRuntimeConfig().public.baseUrl,
        path: (_this$$route2 = this.$route) === null || _this$$route2 === void 0 ? void 0 : _this$$route2.path,
        slug: `/${(_this$$route3 = this.$route) === null || _this$$route3 === void 0 ? void 0 : (_this$$route3$params = _this$$route3.params) === null || _this$$route3$params === void 0 ? void 0 : _this$$route3$params.pathMatch}`,
        currency_code: this.currencyCode.toLocaleLowerCase(),
        destinationName: this.destinationName,
        destinationType: this.destinationType,
        destinationMonth: this.destinationMonth,
        pageSize: this.maxTotalDepartures
      };
      const topDeals = await contextualFetch("/api/nuxt/destination/top-deals", {
        params: requestParams
      });
      this.departures = topDeals || [];
      if (!this.departures.length) {
        var _this$$route4, _this$$route4$params, _this$$route5, _this$$route5$params;
        this.departuresNoResultsData = {
          ...this.departuresNoResultsData,
          displayName: this.destinationName,
          url: `/${(_this$$route4 = this.$route) === null || _this$$route4 === void 0 ? void 0 : (_this$$route4$params = _this$$route4.params) === null || _this$$route4$params === void 0 ? void 0 : _this$$route4$params.pathMatch.substring(0, (_this$$route5 = this.$route) === null || _this$$route5 === void 0 ? void 0 : (_this$$route5$params = _this$$route5.params) === null || _this$$route5$params === void 0 ? void 0 : _this$$route5$params.pathMatch.lastIndexOf("/"))}`
        };
      }
      this.getTopDealsProcessCompletedStatus = true;
    }
  },
  updated() {
    if (this.departures.length > 0 || this.destinationMonth) {
      this.$store.dispatch("app/componentUpdate").catch(err => logger.error("An exception in dispatching from store", {
        error: err
      }));
    }
  },
  methods: {
    closeModal() {
      this.modalData = null;
    },
    shouldDisplayPrice(departure) {
      if (!departure.price || departure.price <= 0) {
        return false;
      }
      if (departure.discountPrice && departure.discountPrice <= 0) {
        return false;
      }
      return true;
    },
    formatDate(date) {
      if (!date) return "";
      return dayjs(date).format("DD MMM YYYY");
    },
    viewMoreTrips() {
      if (this.currentDisplayIndex < this.filteredDepartures.length) {
        this.currentDisplayIndex += DEPARTURES_PER_BATCH;
      }
    }
  }
});