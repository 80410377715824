<template>
  <div
    data-cy="departures"
    :class="{
      'xs:u-margin-left--1 xs:u-margin-right--1 md:u-margin-left--0 md:u-margin-right--0':
        !isLoading && !hasAvailableYearsAndMonths,
    }"
  >
    <DeparturesNoResults
      v-if="!isLoading && !hasAvailableYearsAndMonths"
      :mainCountry="mainCountry"
    />
    <div
      v-if="isLoading && !hasAvailableYearsAndMonths"
      class="departures__loading-departures"
    >
      <Spinner
        :show="true"
        aria-label="Loading departures"
        class="icon--size-2"
      />
      <span>{{ fetchingDeparturesAndAvailabilitiesText }}</span>
    </div>
    <template v-else>
      <div
        class="
          u-margin-bottom--2
          xs:u-padding-left--1 xs:u-padding-right--1
          sm:u-padding-left--0 sm:u-padding-right--0
        "
      >
        <div
          v-if="hasDepartures"
          class="u-padding-bottom--1-5 u-display--inline-flex"
          data-cy="departures__fluid-price-alert"
        >
          <FluidPriceAlert />
        </div>
        <DateButtonSelect
          v-if="hasAvailableYearsAndMonths"
          :dates="availableYearsAndMonths"
          @selected="fetchDeparturesByDate"
        />
      </div>

      <div
        v-if="isLoading && !hasDepartures"
        class="departures__loading-departures"
      >
        <Spinner
          :show="true"
          aria-label="Loading departures"
          class="icon--size-2"
        />
        <span>{{ fetchingDeparturesText }}</span>
      </div>
      <template v-else>
        <Accordion
          v-if="hasDepartures"
          data-cy="departures__departure-days"
          description="List of departures"
        >
          <template slot="content">
            <DeparturesDayColumns
              data-cy="departures__departure-days-columns"
            />
            <DeparturesDay
              v-for="departure in departures"
              :key="departure.startDate"
              :themes="themes"
              :mainCountryCode="mainCountry?.code"
              :productSubType="productSubType"
              :departure="departure"
            />
          </template>
        </Accordion>
        <div
          class="
            xs:u-margin-left--1 xs:u-margin-right--1
            sm:u-margin-left--0 sm:u-margin-right--0
          "
        >
          <DeparturesPaginationButton
            v-if="currentPage < numberOfPages"
            :isLoading="isLoading && hasDepartures"
            @pagination-clicked="fetchNextPage"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import DateButtonSelect from "atlas/src/components/DateButtonSelect/DateButtonSelect.vue";
import Accordion from "atlas/src/components/Accordion/Accordion.vue";
import Spinner from "atlas/src/components/Spinner/Spinner.vue";
import FluidPriceAlert from "../FluidPriceAlert/FluidPriceAlert.vue";
import DeparturesDay from "./DeparturesDay/DeparturesDay.vue";
import DeparturesPaginationButton from "./DeparturesPaginationButton/DeparturesPaginationButton.vue";
import DeparturesNoResults from "./DeparturesNoResults/DeparturesNoResults.vue";
import { DeparturesProps } from "./Props";
import { DeparturesRoomDetailsModalProps } from "./DeparturesRoomDetails/Props";
import DeparturesDayColumns from "./DeparturesDayColumns/DeparturesDayColumns.vue";
import { isEmpty } from "~/lib/utils/isEmpty";

export default Vue.extend({
  name: "Departures",
  components: {
    Accordion,
    DateButtonSelect,
    DeparturesDay,
    Spinner,
    DeparturesPaginationButton,
    DeparturesNoResults,
    DeparturesDayColumns,
    FluidPriceAlert,
  },
  props: {
    availableYearsAndMonths: {
      type: Object as PropType<DeparturesProps["availableYearsAndMonths"]>,
      required: true,
    },
    departures: {
      type: Object as PropType<DeparturesProps["departures"]>,
      required: true,
    },
    currentPage: {
      type: Number as PropType<DeparturesProps["currentPage"]>,
      required: true,
    },
    numberOfPages: {
      type: Number as PropType<DeparturesProps["numberOfPages"]>,
      required: true,
    },
    isLoading: {
      type: Boolean as PropType<DeparturesProps["isLoading"]>,
      required: false,
      default: undefined,
    },
    mainCountry: {
      type: Object as PropType<DeparturesProps["mainCountry"]>,
      required: true,
    },
    themes: {
      type: Array as PropType<DeparturesRoomDetailsModalProps["themes"]>,
      required: false,
      default: () => [],
    },
    productSubType: {
      type: Number as PropType<
        DeparturesRoomDetailsModalProps["productSubType"]
      >,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      fetchingDeparturesAndAvailabilitiesText:
        "Loading trip dates and availabilities...",
      fetchingDeparturesText: "Loading trip dates...",
    };
  },
  computed: {
    hasAvailableYearsAndMonths() {
      return !isEmpty(this.availableYearsAndMonths);
    },
    hasDepartures() {
      return !isEmpty(this.departures);
    },
  },
  methods: {
    fetchNextPage() {
      this.$emit("fetchNextPage");
    },
    fetchDeparturesByDate(selectedDate: string) {
      this.$emit("fetchDeparturesByDate", selectedDate);
    },
  },
});
</script>

<style lang="scss">
@import "./departures";
</style>
