<template>
  <div
    class="departures-room u-cursor--pointer"
    data-cy="departures-room"
    @click="isRadioVisible && toggleRadio()"
  >
    <div class="departures-room__radio-wrapper" :class="computedClasses">
      <input
        v-if="isRadioVisible"
        :id="componentUniqueId"
        type="radio"
        class="departures-room__radio-button u-cursor--pointer"
        :aria-labelledby="'departures-room-label' + componentUniqueId"
        :aria-checked="isSelected"
        :checked="isSelected"
        :value="value"
        data-cy="departures-room-radio"
      />
    </div>
    <label
      :id="'departures-room-label' + componentUniqueId"
      class="departures-room__content-wrapper u-cursor--pointer"
    >
      <slot />
    </label>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { componentUniqueIdMixin } from "~~/lib/mixins/componentUniqueId";

export default Vue.extend({
  name: "DeparturesRoom",
  mixins: [componentUniqueIdMixin],
  model: {
    prop: "model",
    event: "change",
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    showRadio: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data(): { checked: boolean } {
    return {
      checked: false,
    };
  },
  computed: {
    computedClasses(): { "is-selected": boolean } {
      return {
        "is-selected": this.isSelected,
      };
    },
    isRadioVisible(): boolean {
      return this.showRadio;
    },
    isSelected(): boolean {
      return this.model === this.value;
    },
  },
  methods: {
    toggleRadio(): void {
      let value = this.model;

      if (this.value !== value) {
        value = this.value;
      }

      this.checked = true;

      this.$emit("change", value);
    },
  },
});
</script>

<style lang="scss">
@import "./departures-room";
</style>
