<template>
  <Modal
    :show-modal="showModal"
    description="Additional Payments Modal"
    data-cy="additional-payments-modal"
    @close="() => $emit('closeAdditionalPaymentsModal')"
  >
    <template slot="heading">
      <template v-if="isKittyModal">Kitty</template>
      <template v-if="isExclusionsModal">Additional payments</template>
    </template>
    <template slot="content">
      <div class="departures-additional-payments-modal__content">
        <template v-if="isKittyModal">
          <p>
            A kitty is a communal pot of local currency that all travellers
            contribute to equally. It pays for shared additional costs during
            the trip and isn’t included in the trip price.
          </p>
          <p>
            The kitty amount may change, so check your final kitty amount in
            your Essential Trip Information 48 hours before your trip.
          </p>
        </template>
        <template v-if="isExclusionsModal">
          <p>
            Additional payments are compulsory payments to be paid locally and
            aren’t included in the trip price.
          </p>
          <p>
            Find out what additional payments to expect in your Essential Trip
            Information.
          </p>
        </template>
      </div>
    </template>
    <template slot="footer">
      <Button
        class="button button--secondary"
        :onClick="() => $emit('closeAdditionalPaymentsModal')"
        >Close</Button
      >
    </template>
  </Modal>
</template>

<script lang="ts">
import Vue from "vue";
import Modal from "atlas/src/components/Modal/Modal.vue";
import Button from "atlas/src/components/Button/Button.vue";

export default Vue.extend({
  name: "DeparturesAdditionalPaymentsModal",

  components: {
    Modal,
    Button,
  },

  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    additionalPaymentsModalType: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  computed: {
    isKittyModal(): boolean {
      return this.additionalPaymentsModalType === "kitty";
    },

    isExclusionsModal(): boolean {
      return this.additionalPaymentsModalType === "exclusions";
    },
  },
});
</script>
