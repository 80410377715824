<template>
  <div data-cy="departures-room-messaging__standard-twin">
    <p>Please note, room options differ for each trip.</p>

    <p>
      <span class="u-font-weight--bold u-display--block">Twin-share</span>
      Share a room with 2 single beds with a traveller of the same gender (as
      per the gender marker on their passports) or someone you’re travelling
      with.
    </p>

    <p>
      Travelling as a couple?
      <Link href="/contact-us" target="_blank"
        >Contact us to request a double bed.</Link
      >
    </p>

    <p>
      <span class="u-font-weight--bold u-display--block">Single-share</span>
      You can
      <Link href="/contact-us" target="_blank"
        >contact us to request your own room</Link
      >
      on most trips for an additional cost. Please note, there’s limited
      availability.
    </p>

    <p>
      <span class="u-font-weight--bold u-display--block"
        >Want to know more?</span
      >
      Find all the room details for your trip in the Essential Trip Information
      or <Link href="/contact-us" target="_blank">contact us</Link>.
    </p>
  </div>
</template>

<script lang="ts">
import Link from "../../Link/Link.vue";

export default {
  name: "DeparturesRoomMessagingStandardTwin",
  components: {
    Link,
  },
};
</script>
