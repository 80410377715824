<template>
  <Card :image="thumbnail" data-cy="deals-card" :href="$link.create(url)">
    <template slot="heading">
      <strong class="u-display--block u-margin-bottom--1">
        {{ displayName }}
      </strong>
    </template>
    <template slot="content">
      {{ shortDescription }}
    </template>
  </Card>
</template>

<script lang="ts">
import { PropType } from "vue";
import Card from "atlas/src/components/Card/Card.vue";
import { DealsCardProps } from "./Props";

export default {
  name: "DealsCard",
  components: { Card },
  props: {
    displayName: {
      type: String as PropType<DealsCardProps["displayName"]>,
      required: true,
    },
    shortDescription: {
      type: String as PropType<DealsCardProps["shortDescription"]>,
      required: false,
      default: "",
    },
    thumbnail: {
      type: Object as PropType<DealsCardProps["thumbnail"]>,
      required: false,
      default: null,
    },
    url: {
      type: String as PropType<DealsCardProps["url"]>,
      required: true,
    },
  },
};
</script>
