<template>
  <div data-cy="departures-room-messaging__sba">
    <p>Please note, room options and availability differs for each trip.</p>
    <p>
      <span class="u-font-weight--bold u-display--block">Twin-share</span>
      Share a room with someone you’re travelling with.
    </p>
    <p>
      <span class="u-font-weight--bold u-display--block">Single-share</span>
      If you’re travelling solo, please book a single-share room, as this trip
      doesn’t allow you to share a room with other travellers.
    </p>
    <p>
      <span class="u-font-weight--bold u-display--block"
        >Want to know more?</span
      >
      Find all the room details for your trip in the Essential Trip Information
      or <Link href="/contact-us" target="_blank">contact us</Link>.
    </p>
  </div>
</template>

<script lang="ts">
import Link from "../../Link/Link.vue";

export default {
  name: "DeparturesRoomMessagingSBA",
  components: {
    Link,
  },
};
</script>
