<template>
  <div data-cy="departures-room-messaging__sailing">
    <p>
      Please note, room options and availability differs for each trip as our
      yachts are all different.
    </p>
    <p>
      <span class="u-font-weight--bold u-display--block">Share a room</span>
      Book the trip and when you arrive we’ll allocate you to a twin, double,
      triple or quadruple room with other travellers (as per the gender marker
      on their passports), or people you’re travelling with.
    </p>
    <p>This means you may share a double bed with someone else.</p>
    <p>
      <span class="u-font-weight--bold u-display--block"
        >Want your own room?</span
      >
      After you book, you can
      <Link href="/contact-us" target="_blank"
        >contact us to request your own room</Link
      >
      for an additional cost. Please note, there’s limited availability, so
      we’ll do our best to accommodate you.
    </p>
    <p>
      <span class="u-font-weight--bold u-display--block"
        >Want to know more?</span
      >
      <Link href="/boats-ships" target="_blank"
        >Explore the deck plan and features for your yacht</Link
      >
      to see your room options in detail. You can also find all the room details
      for your trip in the Essential Trip Information, or
      <Link href="/contact-us" target="_blank">contact us</Link>.
    </p>
  </div>
</template>

<script lang="ts">
import Link from "../../Link/Link.vue";

export default {
  name: "DeparturesRoomMessagingSailing",
  components: {
    Link,
  },
};
</script>
