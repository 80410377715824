<template>
  <div class="departures-trip-schedule" data-cy="departures-trip-schedule">
    <p class="departures-trip-schedule__heading u-margin-bottom--0-5">
      Trip dates
    </p>
    <div class="u-display--flex u-flex-direction--row">
      <div class="departures-trip-schedule__trip-duration u-margin-right--1">
        {{ numberOfDays }}
        <div class="departures-trip-schedule__trip-duration-days">DAYS</div>
      </div>
      <PointList>
        <PointListItem>
          <div class="departures-trip-schedule__trip-schedule-title">
            TRIP STARTS
          </div>
          <div>{{ `${startCity}, ${startCountry}` }}</div>
          <div>{{ formatDate(departingDate) }}</div>
        </PointListItem>
        <PointListItem>
          <div class="departures-trip-schedule__trip-schedule-title">
            TRIP ENDS
          </div>
          <div>{{ `${endCity}, ${endCountry}` }}</div>
          <div>{{ formatDate(arrivingDate) }}</div>
        </PointListItem>
      </PointList>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import PointList from "atlas/src/components/PointList/PointList.vue";
import PointListItem from "atlas/src/components/PointList/PointListItem.vue";
import dayjs, { extend } from "dayjs";
import utc from "dayjs/plugin/utc.js";

extend(utc);

export default Vue.extend({
  name: "DeparturesTripSchedule",
  components: {
    PointList,
    PointListItem,
  },
  props: {
    startCity: {
      type: String,
      required: true,
    },
    startCountry: {
      type: String,
      required: true,
    },
    endCity: {
      type: String,
      required: true,
    },
    endCountry: {
      type: String,
      required: true,
    },
    departingDate: {
      type: String,
      required: true,
    },
    arrivingDate: {
      type: String,
      required: true,
    },
    numberOfDays: {
      type: Number,
      required: true,
    },
  },
  methods: {
    formatDate(date: string): string {
      return dayjs.utc(date).format("dddd, MMM D, YYYY");
    },
  },
});
</script>

<style lang="scss">
@import "./departures-trip-schedule";
</style>
