<template>
  <div
    v-if="hasValidRoomType && hasValidPrice"
    class="departures-room-option-price__price-details"
    data-cy="departures-room-option-price"
  >
    <div class="departures-room-option-price__price-wrapper">
      <Price
        :value="asValue(price.price)"
        :currencyCode="price.currencyCode.toLocaleUpperCase()"
        :currency-locale="getLocaleByCode($i18n.locale).currencyLocale"
        :showCurrencyCode="true"
        :showDecimalPlaces="false"
        :class="[
          hasValidDiscountedPrice
            ? 'departures-room-option-price__price-when-has-discounted-price'
            : 'departures-room-option-price__price-when-has-not-discounted-price',
          'departures-room-option-price__price',
          'u-margin-right--0-5',
        ]"
      />
      <Price
        v-if="hasValidDiscountedPrice && price.discountedPrice"
        :value="price.discountedPrice"
        :currency-locale="getLocaleByCode($i18n.locale).currencyLocale"
        :currencyCode="price.currencyCode.toLocaleUpperCase()"
        :showCurrencyCode="true"
        :showDecimalPlaces="false"
        class="departures-room-option-price__discounted-price"
      />
    </div>
    <p class="departures-room-option-price__messaging u-margin-bottom--0">
      per
      <span class="u-font-weight--bold">{{ price.ageType }}</span>
      in a
      <span class="u-font-weight--bold">{{
        getTranslatedRoomType(roomType)
      }}</span>
      room
    </p>
  </div>
  <div
    v-else
    class="departures-room-option-price__price-unavailability-details"
  >
    <span class="departures-room-option-price__price-unavailable">
      Price unavailable
    </span>
    <p class="departures-room-option-price__contact-details u-margin-bottom--0">
      <Link href="/contact-us">Contact us</Link>, prices are currently
      unavailable
    </p>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Price from "atlas/src/components/Price/Price.vue";
import Link from "../../Link/Link.vue";
import { isDiscountPriceValid } from "../utils/isDiscountPriceValid";
import { isPriceValid } from "../utils/isPriceValid";
import { DeparturesDayRoomOptionPrice } from "../Props";
import { getTranslatedRoomType } from "../utils/getTranslatedRoomType";
import { getLocaleByCode } from "~/lib/utils/locale/getLocaleByCode";
import { asValue } from "~/lib/utils/asValue";

export default Vue.extend({
  name: "DeparturesRoomOptionPrice",
  components: {
    Price,
    Link,
  },
  props: {
    price: {
      type: Object as PropType<DeparturesDayRoomOptionPrice>,
      required: true,
    },
    roomType: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasValidRoomType(): boolean {
      if (this.roomType && typeof this.roomType === "string") {
        return true;
      }

      return false;
    },

    hasValidPrice(): boolean {
      return isPriceValid(this.price) && this.isValidAgeType();
    },

    hasValidDiscountedPrice(): boolean {
      return isDiscountPriceValid(this.price);
    },
  },
  methods: {
    getLocaleByCode,
    isValidAgeType(): boolean {
      if (
        this.price &&
        this.price.ageType &&
        typeof this.price.ageType === "string"
      ) {
        return true;
      }

      return false;
    },
    getTranslatedRoomType,
    asValue,
  },
});
</script>

<style lang="scss">
@import "./departures-room-option-price";
</style>
