<template>
  <div
    v-if="isPriceAvailable"
    class="departures-additional-payments-link"
    data-cy="departures-style-additional-payments"
  >
    <div
      v-if="hasKitty && isAvailable"
      class="
        departures-additional-payments__kitty-details
        u-text-align--center
        sm:u-text-align--right
        u-margin-bottom--0-5
        md:u-margin-bottom--0
      "
      data-cy="departures-style-additional-payments__kitty-details"
    >
      <span
        class="departures-additional-payments__small-text sm:u-display--inline"
      >
        Includes
        <Price
          class="u-display--inline-block"
          :value="asValue(kittyPrice.price)"
          :currency-code="kittyPrice.currencyCode.toUpperCase()"
          :currency-locale="getLocaleByCode($i18n.locale).currencyLocale"
          :show-decimal-places="false"
          :show-currency-code="true"
        />
      </span>
      <a
        class="
          departures-additional-payments__small-text
          departures-additional-payments__kitty-link
          u-display--inline-block
          u-cursor--pointer
        "
        href="#"
        @click.prevent="() => $emit('additionalPaymentsLinkClicked', 'kitty')"
        >kitty</a
      >
    </div>
    <div
      v-if="hasExclusions && isAvailable"
      class="
        departures-additional-payments__exclusions-details
        u-text-align--center
        sm:u-text-align--right
      "
      data-cy="departures-style-additional-payments__exclusions-details"
    >
      <span
        class="
          departures-additional-payments__small-text
          u-display--inline-block
          sm:u-display--inline
        "
      >
        Includes
        <Price
          :value="asValue(totalExclusionsPrice.price)"
          :currency-code="totalExclusionsPrice.currencyCode.toUpperCase()"
          :currency-locale="getLocaleByCode($i18n.locale).currencyLocale"
          :show-currency-code="true"
          :show-decimal-places="false"
        />
        in
      </span>
      <a
        class="
          departures-additional-payments__small-text
          departures-additional-payments__exclusions-link
          u-display--inline-block
          u-cursor--pointer
        "
        href="#"
        @click.prevent="
          () => $emit('additionalPaymentsLinkClicked', 'exclusions')
        "
        >additional payments</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Price from "atlas/src/components/Price/Price.vue";
import { isAvailable } from "../utils/isAvailable";
import { isPriceValid } from "../utils/isPriceValid";
import { DeparturesDayPrice } from "../Props";
import { getLocaleByCode } from "~/lib/utils/locale/getLocaleByCode";
import { asValue } from "~/lib/utils/asValue";

export default Vue.extend({
  name: "DeparturesAdditionalPayments",

  components: {
    Price,
  },
  props: {
    availability: {
      type: Number,
      default: undefined,
    },
    kittyPrice: {
      type: Object as PropType<DeparturesDayPrice>,
      default: undefined,
    },
    totalExclusionsPrice: {
      type: Object as PropType<DeparturesDayPrice>,
      default: undefined,
    },
    lowestPrice: {
      type: Object as PropType<DeparturesDayPrice>,
      required: false,
      default: undefined,
    },
  },
  computed: {
    hasKitty(): boolean {
      return isPriceValid(this.kittyPrice);
    },
    hasExclusions(): boolean {
      return isPriceValid(this.totalExclusionsPrice);
    },
    isAvailable(): boolean {
      return isAvailable(this.availability);
    },
    isPriceAvailable(): boolean {
      return isPriceValid(this.lowestPrice);
    },
  },
  methods: { getLocaleByCode, asValue },
});
</script>

<style lang="scss">
@import "./departures-additional-payments";
</style>
