<template>
  <div
    v-if="startDate && endDate"
    class="departures-day-header"
    data-cy="departures-day-header"
  >
    <div class="departures-day-header__start-date">
      <span class="xs:u-display--none sm:u-display--block">{{
        formatDate(startDate).weekDay
      }}</span>
      <span class="sm:u-display--none">{{
        formatDate(startDate, true).weekDay
      }}</span>
      <span class="u-font-weight--bold">{{
        formatDate(startDate).monthYear
      }}</span>
    </div>
    <div class="departures-day-header__end-date">
      <span>{{ formatDate(endDate).weekDay }}</span>
      <span class="u-font-weight--bold">
        {{ formatDate(endDate).monthYear }}</span
      >
    </div>
    <div class="departures-day-header__on-sale">
      <template
        v-if="isAvailable && shouldRenderPrices && isThereAnyStyleOnSale"
      >
        <Chip class="chip--small chip--on-sale">On sale</Chip>
      </template>
    </div>
    <div class="departures-day-header__options">
      <div v-if="isAvailable && shouldRenderPrices">
        <div
          v-if="showSpacesLeftMessage"
          class="u-font-weight--bold departures-day-header__spaces-left-message"
          data-cy="departures-day-header__total-availability"
        >
          {{ $tc("space", totalAvailability, { n: totalAvailability }) }} left
        </div>
        <div
          v-else
          class="u-font-weight--bold"
          data-cy="departures-day-header__total-availability"
        >
          Available
        </div>
        <div
          v-if="hasAllRoomOnRequest"
          class="u-font-weight--bold"
          data-cy="departures-day-header__on-request"
        >
          On request
        </div>
      </div>
    </div>
    <div class="departures-day-header__prices u-text-align--right">
      <div
        v-if="!isAvailable"
        class="departures-day-header__not-available-price"
        data-cy="departures-day-header__fully-booked"
      >
        Fully booked
      </div>
      <div
        v-else-if="
          isAvailable &&
          shouldRenderPrices &&
          !isAllRoomsInStylesOneSpaceLeftAndIsFamilyTrip
        "
        class="departures-day-header__prices-wrapper"
      >
        <Price
          v-if="lowestPrice && lowestPrice.discountedPrice"
          class="
            departures-day-header__price
            departures-day-header__price-strike-through
          "
          :value="asValue(lowestPrice.price)"
          :currencyCode="lowestPrice.currencyCode.toLocaleUpperCase()"
          :currency-locale="getLocaleByCode($i18n.locale).currencyLocale"
          :showCurrencyCode="true"
          :showDecimalPlaces="false"
        />
        <Price
          class="
            departures-day-header__price departures-day-header__actual-price
          "
          :value="lowestPriceValue"
          :currencyCode="lowestPriceCurrencyCode"
          :currency-locale="getLocaleByCode($i18n.locale).currencyLocale"
          :showCurrencyCode="true"
          :showDecimalPlaces="false"
        />
      </div>
      <div
        v-else-if="
          isAvailable &&
          !shouldRenderPrices &&
          !isAllRoomsInStylesOneSpaceLeftAndIsFamilyTrip
        "
        class="departures-day-header__not-available-price"
        data-cy="departures-day-header__price-unavailable"
      >
        Price unavailable
      </div>
      <div
        v-else-if="isAllRoomsInStylesOneSpaceLeftAndIsFamilyTrip"
        class="departures-day-header__not-available-price"
        data-cy="departures-day-header__price-unavailable"
      >
        Contact Us
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import dayjs from "dayjs";
import Price from "atlas/src/components/Price/Price.vue";
import Chip from "atlas/src/components/Chip/Chip.vue";
import { DeparturesDay } from "../Props";
import { isAvailable } from "../utils/isAvailable";
import { isPriceValid } from "../utils/isPriceValid";
import { isAvailabilityBetweenZeroAndFive } from "../utils/isAvailabilityBetweenZeroAndFive";
import { isAllRoomsInStylesOneSpaceLeftAndIsFamilyTrip } from "../utils/isAllRoomsInStylesOneSpaceLeftAndIsFamilyTrip";
import { isAllRoomOnRequest } from "../utils/isAllRoomOnRequest";
import { isThereAnyStyleOnSale } from "../utils/isThereAnyStyleOnSale";
import { getLocaleByCode } from "~/lib/utils/locale/getLocaleByCode";
import { asValue } from "~/lib/utils/asValue";

export default Vue.extend({
  name: "DeparturesDayHeader",
  components: {
    Price,
    Chip,
  },
  props: {
    startDate: {
      type: String as PropType<DeparturesDay["startDate"]>,
      required: true,
    },
    endDate: {
      type: String as PropType<DeparturesDay["endDate"]>,
      required: true,
    },
    isFamilyTrip: {
      type: Boolean as PropType<DeparturesDay["isFamilyTrip"]>,
      required: true,
    },
    totalAvailability: {
      type: Number as PropType<DeparturesDay["totalAvailability"]>,
      required: true,
    },
    lowestPrice: {
      type: Object as PropType<DeparturesDay["lowestPrice"]>,
      required: false,
      default: undefined,
    },
    styles: {
      type: Array as PropType<DeparturesDay["styles"]>,
      required: true,
    },
  },
  computed: {
    lowestPriceValue(): number {
      return this.lowestPrice?.discountedPrice
        ? this.lowestPrice.discountedPrice
        : (this.lowestPrice?.price as number);
    },
    lowestPriceCurrencyCode(): string {
      return this.lowestPrice?.currencyCode.toLocaleUpperCase() ?? "";
    },
    formatDate() {
      return (dateString: string, showShortHandDay?: boolean) => {
        if (!dateString) {
          return {
            weekDay: "",
            monthYear: "",
          };
        }
        const date = dayjs(dateString);

        const weekday = showShortHandDay
          ? date.format("ddd")
          : date.format("dddd");

        const dayOfMonth = date.format("D");
        const month = date.format("MMM");
        const year = date.format("YYYY");

        return {
          weekDay: `${weekday} ${dayOfMonth}`,
          monthYear: `${month} ${year}`,
        };
      };
    },
    hasAllRoomOnRequest(): boolean {
      return isAllRoomOnRequest(this.styles);
    },
    shouldRenderPrices(): boolean {
      return isPriceValid(this.lowestPrice);
    },
    showSpacesLeftMessage(): boolean {
      return isAvailabilityBetweenZeroAndFive(this.totalAvailability);
    },
    isAvailable(): boolean {
      return isAvailable(this.totalAvailability);
    },
    isAllRoomsInStylesOneSpaceLeftAndIsFamilyTrip(): boolean {
      return isAllRoomsInStylesOneSpaceLeftAndIsFamilyTrip(
        this.isFamilyTrip,
        this.styles
      );
    },
    isThereAnyStyleOnSale(): boolean {
      return isThereAnyStyleOnSale(this.styles, this.isFamilyTrip);
    },
  },
  methods: { getLocaleByCode, asValue },
});
</script>

<style lang="scss">
@import "./departures-day-header";
</style>
