<template>
  <div v-if="deals && deals.length > 0" class="l-grid">
    <div
      v-for="(deal, index) in deals"
      :key="index"
      class="l-grid__cell l-grid__cell--4-col"
    >
      <DealsCard v-bind="deal" class="u-height--full" />
    </div>
    <div class="l-grid__cell l-grid__cell--4-col">
      <DealsSearchCard class="u-height--full" v-bind="dealsSearchCard" />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { DealsListProps } from "./Props";
import DealsCard from "~/components/DealsCard/DealsCard.vue";
import DealsSearchCard from "~/components/DealsSearchCard/DealsSearchCard.vue";

export default {
  name: "DealsList",
  components: { DealsCard, DealsSearchCard },
  props: {
    deals: {
      type: Array as PropType<DealsListProps["deals"]>,
      required: false,
      default: null,
    },
    dealsSearchCard: {
      type: Object as PropType<DealsListProps["dealsSearchCard"]>,
      required: false,
      default: null,
    },
  },
};
</script>
