<template>
  <div class="departures-style">
    <div
      v-if="hasMultipleStyles"
      class="
        departures-style__style-details-wrapper
        u-margin-bottom--0-5
        u-display--flex
        xs:u-flex-direction--column
        sm:u-flex-direction--row
      "
    >
      <span>
        Style:
        <span class="departures-style__style-type">{{
          styleType ? styleType.toUpperCase() : ""
        }}</span>
      </span>
      <Link href="/trip-styles" class="departures-style__contact-details"
        >Read more about our trip styles</Link
      >
    </div>

    <slot name="vehicleDetails" />
    <slot name="rooms" />
    <slot name="additionalPayments" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Link from "../../Link/Link.vue";

export default Vue.extend({
  name: "DeparturesStyle",
  components: {
    Link,
  },
  props: {
    noOfStyles: {
      type: Number,
      required: true,
      default: undefined,
    },
    styleType: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasMultipleStyles(): boolean {
      return this.noOfStyles > 1;
    },
  },
});
</script>

<style lang="scss">
@import "./departures-style";
</style>
