var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"u-display--flex u-justify-content--flex-end",attrs:{"data-cy":"departures-day-buttons"}},[_c('div',{staticClass:"departures-day-buttons__wrapper"},[(
        _vm.isSelectedRoomAvailable &&
        !_vm.isFamilyTripWithOneAvailability &&
        _vm.isPriceAvailableInSelectedDeparturesRoom &&
        _vm.isCurrentSeason
      )?[(_vm.selectedDeparturesRoom.isOnRequest)?_c('Button',{staticClass:"button--primary button--block u-margin-top--1",attrs:{"href":_vm.$link.create(_vm.bookUrl)}},[_vm._v("\n        Request booking\n      ")]):_c('Button',{staticClass:"button--special button--block departures-day-buttons__button u-margin-top--1",attrs:{"href":_vm.$link.create(_vm.bookUrl)}},[_vm._v("\n        Continue to booking\n      ")])]:(!_vm.isCurrentSeason && _vm.selectedDeparturesRoom.viewTripUrl)?_c('div',[_c('Button',{staticClass:"button--secondary button--block u-margin-top--1",attrs:{"href":_vm.$link.create(_vm.selectedDeparturesRoom.viewTripUrl)}},[_vm._v("\n        View trip\n      ")])],1):_c('div',[_c('Button',{staticClass:"button--secondary button--block u-margin-top--1",attrs:{"href":_vm.$link.create(`/contact-us`)}},[_vm._v("\n        Contact Us\n      ")])],1),_vm._v(" "),(
        _vm.isSelectedRoomAvailable &&
        _vm.selectedDeparturesRoom.isPlaceOnHold &&
        _vm.isCurrentSeason &&
        _vm.isPriceAvailableInSelectedDeparturesRoom &&
        !_vm.$agents.getIsAgentLoggedIn() &&
        (_vm.isPolar || !_vm.hasPriceDiscount) &&
        _vm.$i18n.localeProperties.isOnHoldEnabled
      )?_c('div',[_c('Button',{staticClass:"button--secondary button--block u-margin-top--1",attrs:{"href":_vm.$link.create(_vm.selectedDeparturesRoom.placeOnHoldUrl)}},[_vm._v("\n        Place on hold\n      ")])],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }