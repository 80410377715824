<template>
  <Modal
    :show-modal="showModal"
    :description="`Room Details Modal`"
    class="departures-room-details-modal"
    data-cy="departures-room-details-modal"
    @close="() => $emit('closeRoomDetailsModal')"
  >
    <template slot="heading">Room details</template>
    <template slot="content">
      <DeparturesRoomMessaging
        :themes="themes"
        :mainCountryCode="mainCountryCode"
        :productSubType="productSubType"
        :singleTravellerCompulsorySingleSup="singleTravellerCompulsorySingleSup"
        :priceType="priceType"
      />
    </template>
    <template slot="footer">
      <Button
        class="button button--secondary"
        :onClick="() => $emit('closeRoomDetailsModal')"
        >Close</Button
      >
    </template>
  </Modal>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Modal from "atlas/src/components/Modal/Modal.vue";
import Button from "atlas/src/components/Button/Button.vue";
import DeparturesRoomMessaging from "../DeparturesRoomMessaging/DeparturesRoomMessaging.vue";
import { DeparturesRoomDetailsModalProps } from "./Props";

export default Vue.extend({
  name: "DeparturesRoomDetailsModal",
  components: {
    Modal,
    Button,
    DeparturesRoomMessaging,
  },
  props: {
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    themes: {
      type: Array as PropType<DeparturesRoomDetailsModalProps["themes"]>,
      required: false,
      default: () => [],
    },
    mainCountryCode: {
      type: String as PropType<
        DeparturesRoomDetailsModalProps["mainCountryCode"]
      >,
      required: false,
      default: "",
    },
    productSubType: {
      type: Number as PropType<
        DeparturesRoomDetailsModalProps["productSubType"]
      >,
      required: false,
      default: undefined,
    },
    singleTravellerCompulsorySingleSup: {
      type: Boolean as PropType<
        DeparturesRoomDetailsModalProps["singleTravellerCompulsorySingleSup"]
      >,
      required: false,
      default: false,
    },
    priceType: {
      type: String as PropType<DeparturesRoomDetailsModalProps["priceType"]>,
      required: false,
      default: undefined,
    },
  },
});
</script>
