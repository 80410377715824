<template>
  <AccordionItem
    v-if="!isValidDeparture(departure)"
    :expanded="expanded"
    data-cy="departures-day"
  >
    <template slot="title">
      <DeparturesDayHeader
        :startDate="departure.startDate"
        :endDate="departure.endDate"
        :isFamilyTrip="departure.isFamilyTrip"
        :totalAvailability="departure.totalAvailability"
        :lowestPrice="departure.lowestPrice"
        :styles="departure.styles"
      />
    </template>
    <template slot="content">
      <div class="l-grid">
        <div
          class="l-grid__cell l-grid__cell--4-col l-grid__cell--8-col-tablet"
        >
          <DeparturesTripSchedule
            class="u-margin-bottom--2 md:u-margin-bottom--0"
            :departingDate="departure.startDate"
            :arrivingDate="departure.endDate"
            :startCountry="departure.startCountry"
            :endCountry="departure.endCountry"
            :startCity="departure.startCity"
            :endCity="departure.endCity"
            :numberOfDays="departure.numberOfDays"
          />
        </div>
        <div class="l-grid__cell l-grid__cell--8-col">
          <DeparturesDaySelection
            v-if="departure.styles"
            :styles="departure.styles"
            :isFamilyTrip="departure.isFamilyTrip"
            :isSmallGroupAdventuresTrip="departure.isSmallGroupAdventuresTrip"
            :totalAvailability="departure.totalAvailability"
            :lowestPrice="departure.lowestPrice"
            :isCurrentSeason="departure.isCurrentSeason"
            :themes="themes"
            :mainCountryCode="mainCountryCode"
            :productSubType="productSubType"
            :singleTravellerCompulsorySingleSup="
              departure.singleTravellerCompulsorySingleSup
            "
          />
        </div>
      </div>
    </template>
  </AccordionItem>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import AccordionItem from "atlas/src/components/Accordion/AccordionItem.vue";
import DeparturesDayHeader from "../DeparturesDayHeader/DeparturesDayHeader.vue";
import DeparturesDaySelection from "../DepartureDaySelection/DeparturesDaySelection.vue";
import DeparturesTripSchedule from "../DeparturesTripSchedule/DeparturesTripSchedule.vue";
import { DeparturesDay, DeparturesProps } from "../Props";
import { DeparturesRoomDetailsModalProps } from "../DeparturesRoomDetails/Props";
import { isEmpty } from "~/lib/utils/isEmpty";

export default Vue.extend({
  name: "DeparturesDay",
  components: {
    AccordionItem,
    DeparturesDayHeader,
    DeparturesDaySelection,
    DeparturesTripSchedule,
  },
  props: {
    departure: {
      type: Object as PropType<DeparturesProps["departures"][0]>,
      required: true,
    },
    expanded: {
      required: false,
      type: Boolean,
      default: false,
    },
    themes: {
      type: Array as PropType<DeparturesRoomDetailsModalProps["themes"]>,
      required: false,
      default: () => [],
    },
    mainCountryCode: {
      type: String as PropType<
        DeparturesRoomDetailsModalProps["mainCountryCode"]
      >,
      required: false,
      default: "",
    },
    productSubType: {
      type: Number as PropType<
        DeparturesRoomDetailsModalProps["productSubType"]
      >,
      required: false,
      default: undefined,
    },
  },
  methods: {
    isValidDeparture(departure: DeparturesDay) {
      return isEmpty(departure);
    },
  },
});
</script>
