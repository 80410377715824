<template>
  <Modal
    class="default"
    :show-modal="showModal"
    description="On request modal"
    @close="closeModal"
  >
    <template slot="heading"> On request </template>
    <template slot="content">
      <p>
        You need to make a payment before we can check if there's space for you
        on the trip.
      </p>

      <p>
        We'll confirm within 2 to 4 business days and refund you if there's no
        space.
      </p>
    </template>
    <template slot="footer">
      <Button class="button button--primary" :onClick="closeModal">
        Close</Button
      >
    </template>
  </Modal>
</template>

<script lang="ts">
import Vue from "vue";
import Modal from "atlas/src/components/Modal/Modal.vue";
import Button from "atlas/src/components/Button/Button.vue";

export default Vue.extend({
  name: "DeparturesRoomOptionModal",
  components: {
    Modal,
    Button,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
});
</script>
