<template>
  <component :is="departuresRoomMessagingComponent" />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import DeparturesRoomMessagingStandardTwin from "../DeparturesRoomMessaging/DeparturesRoomMessagingStandardTwin.vue";
import DeparturesRoomMessagingFamily from "../DeparturesRoomMessaging/DeparturesRoomMessagingFamily.vue";
import DeparturesRoomMessagingMultishare from "../DeparturesRoomMessaging/DeparturesRoomMessagingMultishare.vue";
import DeparturesRoomMessagingVRTTSoloTravellersNoShare from "../DeparturesRoomMessaging/DeparturesRoomMessagingVRTTSoloTravellersNoShare.vue";
import DeparturesRoomMessagingVRTTSoloTravellersShare from "../DeparturesRoomMessaging/DeparturesRoomMessagingVRTTSoloTravellersShare.vue";
import DeparturesRoomMessagingSailing from "../DeparturesRoomMessaging/DeparturesRoomMessagingSailing.vue";
import DeparturesRoomMessagingSBA from "../DeparturesRoomMessaging/DeparturesRoomMessagingSBA.vue";
import { DeparturesRoomMessagingProps } from "./Props";
import { ThemeID } from "~~/lib/types/Theme";
import { ProductSubTypeID } from "~~/lib/types/ProductSubType";
import { HayabusaRoomType } from "~~/lib/types/Hayabusa/DeparturesAndAvailabilities";

export default Vue.extend({
  name: "DeparturesRoomMessaging",
  props: {
    themes: {
      type: Array as PropType<DeparturesRoomMessagingProps["themes"]>,
      required: false,
      default: () => [],
    },
    mainCountryCode: {
      type: String as PropType<DeparturesRoomMessagingProps["mainCountryCode"]>,
      required: false,
      default: "",
    },
    productSubType: {
      type: Number as PropType<DeparturesRoomMessagingProps["productSubType"]>,
      required: false,
      default: undefined,
    },
    singleTravellerCompulsorySingleSup: {
      type: Boolean as PropType<
        DeparturesRoomMessagingProps["singleTravellerCompulsorySingleSup"]
      >,
      required: false,
      default: false,
    },
    priceType: {
      type: String as PropType<DeparturesRoomMessagingProps["priceType"]>,
      required: false,
      default: undefined,
    },
  },
  computed: {
    departuresRoomMessagingComponent() {
      if (this.isFamilyMessaging) {
        return DeparturesRoomMessagingFamily;
      }
      if (this.isSailingMessaging) {
        return DeparturesRoomMessagingSailing;
      }
      if (this.isMultiShareMessaging) {
        return DeparturesRoomMessagingMultishare;
      }
      if (this.isSBAMessaging) {
        return DeparturesRoomMessagingSBA;
      }
      if (this.isVRTTSoloTravellersShareMessaging) {
        return DeparturesRoomMessagingVRTTSoloTravellersShare;
      }
      if (this.isVRTTSoloTravellersNoShareMessaging) {
        return DeparturesRoomMessagingVRTTSoloTravellersNoShare;
      }
      return DeparturesRoomMessagingStandardTwin;
    },
    isFamilyMessaging() {
      // 04 Product: Theme: Elements ID = Family
      // /italy/italy-family-holiday-143859
      return this.themes?.find((theme) => theme.id === ThemeID.Family);
    },
    isSailingMessaging() {
      // 04 Product: Theme: Elements ID = Sailing
      // 04 Product: Main Country: Code  != EC
      // /greece/sail-greece-mykonos-santorini-143641
      // EC Sailing: /ecuador/absolute-galapagos-le-grande-daphne-125801
      const isSailingTheme = this.themes?.find(
        (theme) => theme.id === ThemeID.Sailing
      );
      const isMainCountryNotEcuador = this.mainCountryCode !== "EC";
      return isSailingTheme && isMainCountryNotEcuador;
    },
    isMultiShareMessaging() {
      // Departure: PriceType = Multi Share
      // /australia/trek-cradle-mountain-overland-track-143864
      return this.priceType === HayabusaRoomType.MULTI;
    },
    isSBAMessaging() {
      // 04 Product: Product Sub Type: Elements ID = SBA
      // /thailand/thailand-hilltribe-trek-145251
      return this.productSubType === ProductSubTypeID.SBA;
    },
    isVRTTSoloTravellersShareMessaging() {
      // 04 Product: Product Sub Type: Elements ID = VRTT
      // Departure: SingleTravellerCompulsorySingleSupFlag = false
      // /antarctica/best-antarctica-wildlife-explorer-ocean-endeavour-139303
      return (
        this.productSubType === ProductSubTypeID.VRTT &&
        !this.singleTravellerCompulsorySingleSup
      );
    },
    isVRTTSoloTravellersNoShareMessaging() {
      // 04 Product: Product Sub Type: Elements ID = VRTT
      // Departure: SingleTravellerCompulsorySingleSupFlag = true
      // Cannot check this as singleTravellerCompulsorySingleSup is not implemented in Hayabusa
      return (
        this.productSubType === ProductSubTypeID.VRTT &&
        this.singleTravellerCompulsorySingleSup
      );
    },
  },
});
</script>
