<template>
  <div
    class="u-display--flex u-justify-content--flex-end"
    data-cy="departures-day-buttons"
  >
    <div class="departures-day-buttons__wrapper">
      <template
        v-if="
          isSelectedRoomAvailable &&
          !isFamilyTripWithOneAvailability &&
          isPriceAvailableInSelectedDeparturesRoom &&
          isCurrentSeason
        "
      >
        <Button
          v-if="selectedDeparturesRoom.isOnRequest"
          :href="$link.create(bookUrl)"
          class="button--primary button--block u-margin-top--1"
        >
          Request booking
        </Button>
        <Button
          v-else
          :href="$link.create(bookUrl)"
          class="
            button--special button--block
            departures-day-buttons__button
            u-margin-top--1
          "
        >
          Continue to booking
        </Button>
      </template>

      <div v-else-if="!isCurrentSeason && selectedDeparturesRoom.viewTripUrl">
        <Button
          :href="$link.create(selectedDeparturesRoom.viewTripUrl)"
          class="button--secondary button--block u-margin-top--1"
        >
          View trip
        </Button>
      </div>

      <div v-else>
        <Button
          :href="$link.create(`/contact-us`)"
          class="button--secondary button--block u-margin-top--1"
        >
          Contact Us
        </Button>
      </div>

      <div
        v-if="
          isSelectedRoomAvailable &&
          selectedDeparturesRoom.isPlaceOnHold &&
          isCurrentSeason &&
          isPriceAvailableInSelectedDeparturesRoom &&
          !$agents.getIsAgentLoggedIn() &&
          (isPolar || !hasPriceDiscount) &&
          $i18n.localeProperties.isOnHoldEnabled
        "
      >
        <Button
          :href="$link.create(selectedDeparturesRoom.placeOnHoldUrl)"
          class="button--secondary button--block u-margin-top--1"
        >
          Place on hold
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import { DeparturesDay, DeparturesDayRoomOptionPrice } from "../Props";
import { isAvailable } from "../utils/isAvailable";
import { isPriceValid } from "../utils/isPriceValid";
import { isFamilyTripWithOneAvailability } from "../utils/isFamilyTripWithOneAvailability";
import { isAnyRoomOptionUnderDiscount } from "../utils/isAnyRoomOptionUnderDiscount";
import { DeparturesRoomDetailsModalProps } from "../DeparturesRoomDetails/Props";

export default Vue.extend({
  name: "DeparturesDayButtons",
  components: {
    Button,
  },
  props: {
    isFamilyTrip: {
      type: Boolean as PropType<DeparturesDay["isFamilyTrip"]>,
      required: true,
    },
    selectedDeparturesRoom: {
      type: Object as PropType<DeparturesDay["styles"][0]["rooms"][0]>,
      required: true,
    },
    isCurrentSeason: {
      type: Boolean as PropType<DeparturesDay["isCurrentSeason"]>,
      required: true,
    },
    themes: {
      type: Array as PropType<DeparturesRoomDetailsModalProps["themes"]>,
      required: false,
      default: () => [],
    },
  },
  computed: {
    bookUrl(): string | undefined {
      if (this.$agents.getIsAgentLoggedIn()) {
        return this.selectedDeparturesRoom.agentBookUrl;
      }
      return this.selectedDeparturesRoom.bookUrl;
    },

    isFamilyTripWithOneAvailability(): boolean {
      return isFamilyTripWithOneAvailability(
        this.isFamilyTrip,
        this.selectedDeparturesRoom.availability
      );
    },

    isPriceAvailableInSelectedDeparturesRoom(): boolean {
      const isPriceAvailable = this.selectedDeparturesRoom.roomOptions.some(
        (roomOption) => {
          return roomOption.roomOptionPrices.some(
            (roomOptionPrice: DeparturesDayRoomOptionPrice) => {
              return isPriceValid(roomOptionPrice);
            }
          );
        }
      );
      return isPriceAvailable;
    },

    isSelectedRoomAvailable() {
      return isAvailable(this.selectedDeparturesRoom.availability);
    },

    hasPriceDiscount() {
      return isAnyRoomOptionUnderDiscount(
        this.selectedDeparturesRoom.roomOptions
      );
    },

    isPolar() {
      return this.themes?.some((theme) => theme?.title === "Polar");
    },
  },
});
</script>

<style lang="scss">
@import "./departures-day-buttons";
</style>
