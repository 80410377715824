<template>
  <div data-cy="departures-day-selection">
    <DeparturesAccommodationDetails :styles="styles" />

    <form @submit.prevent>
      <DeparturesStyle
        v-for="(style, styleIndex) of $props.styles"
        :key="styleIndex"
        :styleType="style.styleType"
        :noOfStyles="$props.styles.length"
        class="u-margin-bottom--1"
      >
        <template slot="vehicleDetails">
          <DeparturesVehicleDetails
            v-if="styleIndex === 0 && style.vehicleName"
            :vehicleName="style.vehicleName"
            class="u-margin-bottom--1-5"
          />
        </template>
        <template slot="rooms">
          <template v-for="(room, roomIndex) in style.rooms">
            <!-- @vue-ignore -->
            <DeparturesRoom
              :key="roomIndex"
              v-model="selectedRoomForBooking"
              :value="room.value"
              :showRadio="isRoomValid(isFamilyTrip, room)"
              class="u-margin-bottom--0-5"
            >
              <template
                v-for="(roomOption, roomOptionIndex) in room.roomOptions"
              >
                <DeparturesRoomOption
                  :key="roomOptionIndex"
                  :isFamilyTrip="isFamilyTrip"
                  :roomType="roomOption.roomType"
                  :isOnRequest="room.isOnRequest"
                  :availability="room.availability"
                  :roomOptionPrices="roomOption.roomOptionPrices"
                >
                  <DeparturesSingleSupplementMessaging
                    v-if="
                      roomOptionIndex === room.roomOptions.length - 1 &&
                      isSingleSupplementVisible(room.roomOptions)
                    "
                    :singleSupplementPrice="room.singleSupplementPrice"
                  />
                  <DeparturesRoomDetailsLink
                    v-if="roomOptionIndex === room.roomOptions.length - 1"
                    class="u-margin-top--1"
                    :room="room"
                    @roomDetailsClicked="openRoomDetailsModal"
                  />
                </DeparturesRoomOption>

                <Separator
                  v-if="roomOptionIndex !== room.roomOptions.length - 1"
                  :key="`${roomOptionIndex}-separator`"
                />
              </template>
            </DeparturesRoom>
          </template>
        </template>
        <template slot="additionalPayments">
          <DeparturesAdditionalPayments
            v-if="isAdditionalPaymentsVisible(isFamilyTrip, style)"
            :availability="totalAvailabilityByStyle(style)"
            :kittyPrice="style.kittyPrice"
            :totalExclusionsPrice="style.totalExclusionsPrice"
            :lowestPrice="lowestPrice"
            @additionalPaymentsLinkClicked="openAdditionalPaymentsModal"
          />
        </template>
      </DeparturesStyle>

      <DeparturesAdditionalPaymentsModal
        :showModal="showAdditionalPaymentsModal"
        :additionalPaymentsModalType="selectedAdditionalPaymentsModalType"
        @closeAdditionalPaymentsModal="closeAdditionalPaymentsModal"
      />

      <DeparturesRoomDetailsModal
        :showModal="showRoomDetailsModal"
        v-bind="departuresRoomDetailsModalProps"
        @closeRoomDetailsModal="closeRoomDetailsModal"
      />

      <DeparturesDayButtons
        :isCurrentSeason="isCurrentSeason"
        :isFamilyTrip="isFamilyTrip"
        :selectedDeparturesRoom="selectedDeparturesRoom"
        :themes="themes"
      />
    </form>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Separator from "atlas/src/components/Separator/Separator.vue";
import DeparturesDayButtons from "../DeparturesDayButtons/DeparturesDayButtons.vue";
import DeparturesRoom from "../DeparturesRoom/DeparturesRoom.vue";
import DeparturesRoomOption from "../DeparturesRoomOption/DeparturesRoomOption.vue";
import DeparturesStyle from "../DeparturesStyle/DeparturesStyle.vue";
import DeparturesRoomDetailsLink from "../DeparturesRoomDetails/DeparturesRoomDetailsLink.vue";
import DeparturesRoomDetailsModal from "../DeparturesRoomDetails/DeparturesRoomDetailsModal.vue";
import DeparturesVehicleDetails from "../DeparturesVehicleDetails/DeparturesVehicleDetails.vue";
import DeparturesAccommodationDetails from "../DeparturesAccommodationDetails/DeparturesAccommodationDetails.vue";
import DeparturesAdditionalPayments from "../DeparturesAdditionalPayments/DeparturesAdditionalPayments.vue";
import DeparturesAdditionalPaymentsModal from "../DeparturesAdditionalPayments/DeparturesAdditionalPaymentsModal.vue";
import DeparturesSingleSupplementMessaging from "../DeparturesSingleSupplementMessaging/DeparturesSingleSupplementMessaging.vue";

import { DeparturesDay, DeparturesDayRoom } from "../Props";
import { isRoomValid } from "../utils/isRoomValid";
import { isPriceValid } from "../utils/isPriceValid";
import { isAllRoomsInStyleOneSpaceLeftAndIsFamilyTrip } from "../utils/isAllRoomsInStyleOneSpaceLeftAndIsFamilyTrip";
import { isThereValidRoomOptionPrice } from "../utils/isThereValidRoomOptionPrice";
import { DeparturesRoomDetailsModalProps } from "../DeparturesRoomDetails/Props";
import { ThemeID } from "~~/lib/types/Theme";

type AddtionalPaymentsModalType = "kitty" | "exclusions";

export default Vue.extend({
  name: "DeparturesDaySelection",
  components: {
    DeparturesStyle,
    DeparturesRoom,
    DeparturesRoomOption,
    DeparturesRoomDetailsLink,
    DeparturesRoomDetailsModal,
    DeparturesVehicleDetails,
    DeparturesAccommodationDetails,
    Separator,
    DeparturesDayButtons,
    DeparturesAdditionalPayments,
    DeparturesAdditionalPaymentsModal,
    DeparturesSingleSupplementMessaging,
  },
  props: {
    styles: {
      type: Array as PropType<DeparturesDay["styles"]>,
      required: true,
    },
    isFamilyTrip: {
      type: Boolean as PropType<DeparturesDay["isFamilyTrip"]>,
      required: true,
    },
    isSmallGroupAdventuresTrip: {
      type: Boolean as PropType<DeparturesDay["isSmallGroupAdventuresTrip"]>,
      required: true,
    },
    totalAvailability: {
      type: Number as PropType<DeparturesDay["totalAvailability"]>,
      required: true,
    },
    isCurrentSeason: {
      type: Boolean as PropType<DeparturesDay["isCurrentSeason"]>,
      required: true,
      default: undefined,
    },
    lowestPrice: {
      type: Object as PropType<DeparturesDay["lowestPrice"]>,
      required: false,
      default: undefined,
    },
    themes: {
      type: Array as PropType<DeparturesRoomDetailsModalProps["themes"]>,
      required: false,
      default: () => [],
    },
    mainCountryCode: {
      type: String as PropType<
        DeparturesRoomDetailsModalProps["mainCountryCode"]
      >,
      required: false,
      default: "",
    },
    productSubType: {
      type: Number as PropType<
        DeparturesRoomDetailsModalProps["productSubType"]
      >,
      required: false,
      default: undefined,
    },
    singleTravellerCompulsorySingleSup: {
      type: Boolean as PropType<
        DeparturesRoomDetailsModalProps["singleTravellerCompulsorySingleSup"]
      >,
      required: false,
      default: false,
    },
  },
  data(): {
    selectedRoomForBooking: string;
    showRoomDetailsModal: boolean;
    selectedRoomForModal?: DeparturesDayRoom;
    showAdditionalPaymentsModal: boolean;
    selectedAdditionalPaymentsModalType?: AddtionalPaymentsModalType;
  } {
    return {
      selectedRoomForBooking: "",
      showRoomDetailsModal: false,
      selectedRoomForModal: undefined,
      showAdditionalPaymentsModal: false,
      selectedAdditionalPaymentsModalType: undefined,
    };
  },
  computed: {
    selectedDeparturesRoom() {
      let selectedDeparturesRoom: DeparturesDayRoom =
        {} as unknown as DeparturesDayRoom;

      this.styles.forEach((style) => {
        style.rooms.forEach((room) => {
          if (room.value === this.selectedRoomForBooking) {
            selectedDeparturesRoom = room;
          }
        });
      });

      return selectedDeparturesRoom;
    },
    departuresRoomDetailsModalProps() {
      const departuresRoomDetailsModalProps: DeparturesRoomDetailsModalProps = {
        mainCountryCode: this.mainCountryCode,
        priceType: this.selectedRoomForModal?.roomOptions?.[0]?.roomType,
        productSubType: this.productSubType,
        singleTravellerCompulsorySingleSup:
          this.singleTravellerCompulsorySingleSup,
        themes: this.themes,
      };
      return departuresRoomDetailsModalProps;
    },

    isSailingTrip() {
      return !!this.themes?.find((theme) => theme.id === ThemeID.Sailing);
    },
  },
  created() {
    this.selectedRoomForBooking = this.setInitialSelectedRoomForBooking(
      this.styles
    );
  },
  methods: {
    openRoomDetailsModal(room: DeparturesDayRoom) {
      this.selectedRoomForModal = room;
      this.showRoomDetailsModal = true;
    },
    closeRoomDetailsModal() {
      this.showRoomDetailsModal = false;
    },

    openAdditionalPaymentsModal(modalType: AddtionalPaymentsModalType) {
      this.selectedAdditionalPaymentsModalType = modalType;
      this.showAdditionalPaymentsModal = true;
    },

    closeAdditionalPaymentsModal() {
      this.showAdditionalPaymentsModal = false;
    },

    totalAvailabilityByStyle(style: DeparturesDay["styles"][0]): number {
      const totalAvailability = style.rooms.reduce(
        (
          accumulator: number,
          currentValue: DeparturesDay["styles"][0]["rooms"][0]
        ) => accumulator + (currentValue?.availability ?? 0),
        0
      );
      return totalAvailability;
    },
    setInitialSelectedRoomForBooking(styles: DeparturesDay["styles"]): string {
      let selectedRoom = "";

      for (const [styleIndex, style] of styles.entries()) {
        for (const [roomIndex, room] of style.rooms.entries()) {
          if (isRoomValid(this.isFamilyTrip, room)) {
            selectedRoom = styles[styleIndex].rooms[roomIndex].value;
            break;
          }
        }

        if (selectedRoom) break;
      }

      return selectedRoom;
    },

    isAdditionalPaymentsVisible(
      isFamilyTrip: boolean,
      style: DeparturesDay["styles"][0]
    ) {
      if (isAllRoomsInStyleOneSpaceLeftAndIsFamilyTrip(isFamilyTrip, style)) {
        return false;
      }

      return (
        isPriceValid(style.kittyPrice) ||
        isPriceValid(style.totalExclusionsPrice)
      );
    },

    isSingleSupplementVisible(
      departureRoomOptions: DeparturesDay["styles"][0]["rooms"][0]["roomOptions"]
    ) {
      if (!isThereValidRoomOptionPrice(departureRoomOptions)) {
        return false;
      }

      return (
        this.isSmallGroupAdventuresTrip &&
        !this.isFamilyTrip &&
        !this.isSailingTrip
      );
    },

    isRoomValid(
      isFamilyTrip: boolean,
      departureRoom: DeparturesDay["styles"][0]["rooms"][0]
    ) {
      return isRoomValid(isFamilyTrip, departureRoom);
    },
  },
});
</script>
