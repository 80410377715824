import {
  HayabusaAgeType,
  HayabusaRoomType,
} from "~~/lib/types/Hayabusa/DeparturesAndAvailabilities";

export enum TranslatedRoomType {
  SINGLE = "single share",
  TWIN = "twin share",
  MULTI = "shared",
}

export interface DeparturesDayPrice {
  price?: number;
  discountedPrice?: number;
  currencyCode: string;
}

export interface DeparturesDayRoomOptionPrice extends DeparturesDayPrice {
  ageType?: HayabusaAgeType;
}

export type DeparturesDayRoomOption = {
  roomOptionPrices: DeparturesDayRoomOptionPrice[];
  roomType: HayabusaRoomType | string;
};

export type DeparturesDayRoom = {
  departureId: number;
  productId: number;
  value: string;
  isOnRequest: boolean;
  isPlaceOnHold: boolean;
  availability: number;
  roomOptions: DeparturesDayRoomOption[];

  singleSupplementPrice?: DeparturesDayPrice;
  bookUrl?: string;
  agentBookUrl?: string;
  placeOnHoldUrl?: string;
  viewTripUrl?: string;
};

export type DeparturesDayStyle = {
  styleType: string;
  vehicleName?: string;
  kittyPrice?: DeparturesDayPrice;
  totalExclusionsPrice?: DeparturesDayPrice;
  rooms: DeparturesDayRoom[];
};

export type DeparturesDay = {
  startDate: string;
  endDate: string;
  totalAvailability: number;
  startCity: string;
  startCountry: string;
  endCity: string;
  endCountry: string;
  numberOfDays: number;
  isFamilyTrip: boolean;
  isSmallGroupAdventuresTrip: boolean;
  isCurrentSeason?: boolean;
  lowestPrice?: DeparturesDayPrice;
  styles: DeparturesDayStyle[];
  singleTravellerCompulsorySingleSup: boolean;
};

export type AvailableYearsAndMonths = Record<string, string[]>;

export type Departures = Record<string, DeparturesDay>;

export type MainCountry = {
  displayName?: string;
  url?: string;
  code?: string;
};

export type DeparturesProps = {
  availableYearsAndMonths: AvailableYearsAndMonths;
  departures: Departures;
  numberOfPages: number;
  currentPage: number;
  isLoading?: boolean;
  mainCountry?: MainCountry;
  pageType?: string;
  title?: string;
};
